import { theme } from "../themes/base";

export const SmartlookProjectKey = process.env.SMARTLOOK_KEY || "";

export function isProdAWSAccount(): boolean {
  let prod: boolean | false;
  if (process.env.CDK_DEFAULT_ACCOUNT) {
    // For CDK pipeline self-mutation
    prod = process.env.CDK_DEFAULT_ACCOUNT === "597908126950";
  } else {
    prod = false;
  }

  return prod;
}

export const primaryGreen = "#008566"; // TA ext green
export const secondaryBlue = "#009DDB"; // trueml cyan
export const primaryBlue = "#003D98"; // primary navy
export const retainViolet = "#9E5ABE";

// color range
export const colorRange = [
  theme.colors.primary,
  theme.colors.tertiary,
  theme.colors.secondary,
  theme.colors.retainViolet,
  theme.colors.secondaryGreen,
  theme.colors.charcoal70,
  theme.colors.extendedGreen,
  theme.colors.darkCharcoal,
];

export const formattedNumber = (
  value: number | bigint = 1,
  maximumFractionDigits?: number,
) =>
  new Intl.NumberFormat("en-US", {
    maximumFractionDigits,
  }).format(value);

export const formattedPercentage = (
  maximumFractionDigits: number,
  value: number | bigint,
) =>
  new Intl.NumberFormat("en-US", {
    style: "percent",
    maximumFractionDigits,
  }).format(value);

export const formattedCurrency = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
}).format;

export const formattedDate = (options: any) =>
  new Intl.DateTimeFormat("en-Us", options);
